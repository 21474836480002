export default () => ({
    async init(){
        if (window.VK){
            this.createWidget()
            return;
        }

        const script = document.createElement('script')
        script.src = "https://vk.com/js/api/openapi.js?168"
        script.addEventListener('load', () => {
            VK.init({
                apiId: 53043032,
                onlyWidgets: true
            });

            this.createWidget()
        })
        document.head.appendChild(script)
    },

    createWidget(){
        VK.Widgets.Group(this.$root.id, {
            mode: 3,
            wide: 1,
            height: 185,
            color1: "FFFFFF",
            color2: "000000",
            color3: "E51A71",
        }, 215629485);
    }
})