import Alpine from "alpinejs"
import intersect from "@alpinejs/intersect"

Alpine.plugin(intersect)

import developmentSlider from "./developmentSlider"
import professionalsSlider from "./professionalsSlider"
import stagesOfWorkSlider from "./stagesOfWorkSlider"
import playVideo from "./playVideo"
import partnersSlider from "./partnersSlider"
import phoneInputMask from "./phoneInputMask"
import Accordion from "./Accordion"
import documentsGrid from "./documentsGrid"
import fancyboxGallery from "./fancyboxGallery"
import contentSlider from "./contentSlider"
import casesSlider from "./casesSlider"
import pageHeader from "./pageHeader"
import contentProjectSlider from "./contentProjectSlider"
import areasOfWork from "./areasOfWork"
import VKWidget from "./VKWidget"

Alpine.data("developmentSlider", developmentSlider)
Alpine.data("professionalsSlider", professionalsSlider)
Alpine.data("stagesOfWorkSlider", stagesOfWorkSlider)
Alpine.data("playVideo", playVideo)
Alpine.data("partnersSlider", partnersSlider)
Alpine.data("phoneInputMask", phoneInputMask)
Alpine.data("Accordion", Accordion)
Alpine.data("documentsGrid", documentsGrid)
Alpine.data("fancyboxGallery", fancyboxGallery)
Alpine.data("contentSlider", contentSlider)
Alpine.data("casesSlider", casesSlider)
Alpine.data("pageHeader", pageHeader)
Alpine.data("contentProjectSlider", contentProjectSlider)
Alpine.data("areasOfWork", areasOfWork)
Alpine.data("VKWidget", VKWidget)

Alpine.store("mobileMenu", {
	open: false,
	toggle() {
		this.open = !this.open
	},
})

Alpine.store("schoolModal",{
	open: false,
	init(){
		if (localStorage.getItem('schoolModalShown') == 'Y')
			return

		setTimeout(() => {
			localStorage.setItem('schoolModalShown', 'Y')
			this.open = true
		}, 30000)
	}
})

Alpine.store("successModal", {
	open: false
})

Alpine.store("header", {
	isSticky: false,
})

Alpine.start()
